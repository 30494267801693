import React from "react";

import TagsArea from "../../components/TagsArea";
import LogoArea from "../../components/LogoArea";
import TextArea from "../../components/TextArea";

export default function Home() {
  document.title = "A B D U L L A H";

  return (
    <React.Fragment>
      <LogoArea />
      <TagsArea position="50" home={true} />
      <TextArea
        home={true}
        title={"Hi, I'm Abdullah, Web Developer!"}
        subtitle={"Front-End / Mobile Developer"}
      ></TextArea>
    </React.Fragment>
  );
}
