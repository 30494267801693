import React from "react";

import TagsArea from "../../components/TagsArea";
import LogoArea from "../../components/LogoArea";
import TextArea from "../../components/TextArea";

export default function About() {
  document.title = "About | A B D U L L A H";
  return (
    <React.Fragment>
      <LogoArea />
      <TagsArea />
      <TextArea title="About-Me">
        <p>
          I am Abdullah Hunais a passionate Front-end developer and interested
          in both Android application and Web Development.I am a fast learner.
        </p>
        <p>MORE more...</p>
      </TextArea>
    </React.Fragment>
  );
}
