import React from "react";

import TagsArea from "../../components/TagsArea";
import LogoArea from "../../components/LogoArea";
import TextArea from "../../components/TextArea";

export default function About() {
  document.title = "Contact | A B D U L L A H";
  return (
    <React.Fragment>
      <LogoArea />
      <TagsArea />
      <TextArea title="Contact"></TextArea>
    </React.Fragment>
  );
}
